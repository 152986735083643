/* .grace_associate_about_us_div img {
    width: 73%;
    height: 450px;
    object-fit: cover;
    border-radius: 12px;
    margin: 0px 78px;
    border: 3px solid #003566;
} */

.grace_associate_about_us_img_div {
    width: 100%;
    height: 450px;
    border: 3px solid #003566;
    border-radius: 12px;
}

.grace_associate_about_us_img_div img {
    width: 75%;
    height: 446px;
    object-fit: contain;
    border-radius: 12px;
    margin: 0px 80px;
}

.grace_associate_about_us_content {
    width: 65%;
    background: white !important;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
    height: 225px;
    padding: 20px;
    margin: 0px 101px;
    margin-top: -70px;
    z-index: 6;
    position: relative;
    opacity: 0;
    /* Start as invisible */
    transform: translateY(50px);
    /* Initially positioned below */
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.grace_associate_about_us_content.in-view {
    opacity: 1;
    /* Fade in */
    transform: translateY(0);
    /* Move to its original position */
}

.grace_associate_about_us_content h4 {
    color: #003566;
    text-align: center;
    font-weight: bold;
}

.grace_associate_about_us_content p {
    text-align: center;
    color: black;
    font-size: 14px;
}

section.grace_associate_about_us h2 {
    color: #003566;
    text-align: center;
    font-weight: bold;
    -webkit-text-decoration: underline solid hsl(208.8deg 98.04% 20%) 4px;
    text-decoration: underline solid hsl(208.8deg 98.04% 20%) 4px;
    margin-bottom: 60px;
}

section.grace_associate_about_us {
    margin-bottom: 70px;
}

/* media query */

@media (max-width: 768px) {
    .grace_associate_about_us_content {
        width: 100%;
        background: white !important;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
        height: 225px;
        padding: 20px;
        margin: 0px;
        margin-top: -70px;
        z-index: 6;
        position: relative;
    }

    .grace_associate_about_us_content {
        width: 100%;
        background: white !important;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
        height: 225px;
        padding: 20px;
        margin: 0px;
        margin-top: -70px;
        z-index: 6;
        border-top-left-radius: 0px;
        position: relative;
        border-top-right-radius: 0px;
    }

    .grace_associate_about_us_div {
        margin-top: 45px;
    }

    section.grace_associate_about_us h2 {
        margin-bottom: 0px;
    }

    .grace_associate_about_us_img_div img {
        width: 100%;
        height: 446px;
        object-fit: contain;
        border-radius: 12px;
        margin: 0px;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .grace_associate_about_us_content {
        width: 100%;
        background: white !important;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
        height: 225px;
        padding: 20px;
        margin: 0px;
        margin-top: -70px;
        z-index: 6;
        border-top-left-radius: 0px;
        position: relative;
        border-top-right-radius: 0px;
    }

    .grace_associate_about_us_img_div img {
        width: 100%;
        height: 446px;
        object-fit: contain;
        border-radius: 12px;
        margin: 0px;
    }
}