.bannerContainer {
    display: block;
}

.bannerContainer::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}

button.banner_button1 {
    border-radius: 40px;
    background-color: rgb(3 51 102);
    border: none;
    color: white;
    font-size: 18px;
    padding: 20px 60px;
    margin-top: 30px;
    --animation-depth: 0.375em;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

button.banner_button1:hover {
    box-shadow: black 1.95px 4.95px 2.6px;
    transform: translateY(calc(-1*var(--animation-depth, .375em)));
}

button.banner_button2 {
    border-radius: 40px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    padding: 16px 60px;
    margin-top: 30px;
    border: 3px solid white;
    margin-left: 25px;
    --animation-depth: 0.375em;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

button.banner_button2:hover {
    box-shadow: black 1.95px 4.95px 2.6px;
    transform: translateY(calc(-1*var(--animation-depth, .375em)));
}

.banner-static {
    width: 47%;
    background: white;
    padding: 26px;
    border-radius: 50px;
    z-index: 1;
    position: relative;
    top: -40px;
    text-align: center;
    /* left: 25%; */
    z-index: 1;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
}

.banner-static a {
    text-decoration: none;
    padding-left: 0px;
    color: black;
    font-size: 16px;
    margin: 0px 35px;
}

.banner-static.sticky {
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
}

.banner-static-s {
    width: 47%;
    background: white;
    padding: 26px;
    border-radius: 50px;
    z-index: 1;
    position: relative;
    top: -40px;
    text-align: center;
    /* left: 25%; */
    z-index: 1;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
}

.banner-static-s a {
    text-decoration: none;
    padding-left: 0px;
    color: black;
    font-size: 16px;
    margin: 0px 35px;
}

.banner-static-s.sticky {
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
}

.banner-static-banner {
    width: 47%;
    background: white;
    padding: 26px;
    border-radius: 50px;
    z-index: 1;
    position: relative;
    top: -40px;
    text-align: center;
    /* left: 25%; */
    z-index: 1;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
}

.banner-static-banner a {
    text-decoration: none;
    padding-left: 0px;
    color: black;
    font-size: 16px;
    margin: 0px 35px;
}

.banner-static-banner.sticky {
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
}


#mobile_banner {
    background-image: url(http://localhost:3000/assets/home%20page%20grace%20banner.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

div#mobile_banner {
    padding: 25px;
}

div#mobile_banner {
    display: none;
}

@media(min-width: 768px) {
    .center-bottom-container {
        display: none;
    }
}

/* media query */

@media (max-width: 768px) {
    button.banner_button2 {
        border-radius: 40px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 18px;
        padding: 16px 60px;
        margin-top: 30px;
        border: 3px solid white;
        margin-left: 0px !important;
    }

    .bannerContainer {
        display: none;
    }

    div#mobile_banner {
        display: block;
        padding: 25px;
        color: white;
    }

    .banner-static {
        display: none;
    }

    section.grace_service_section {
        margin-bottom: 45px;
        margin-top: 45px;
    }

    h4.industry_h4 {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        margin: 5% 0px !important;
        font-size: 45px;
    }

    .grace-service_div {
        border-bottom: none !important;
        padding-bottom: 25px;
        padding-top: 25px;
    }
}


/* CenterBottomContainer.css */


/* Styling for each link */
.center-link {
    background-color: white;
    /* Button-like blue background */
    color: black;
    /* White text color */
    padding: 10px 20px;
    /* Padding for the links */
    border-radius: 20px;
    /* Rounded edges for buttons */
    text-decoration: none;
    /* Remove underline from links */
    font-size: 16px;
    /* Text size */
    margin: 0 10px;
    /* Spacing between links */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Add a shadow for floating effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth hover animation */
    text-align: center;
    /* Center text in the button */
}

/* Hover effect */
.center-link:hover {
    transform: scale(1.1);
    /* Slightly enlarge on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    /* Enhanced shadow on hover */
}

@media(max-width: 400px) {
    .center-link {
        margin: 0px;
    }
}

@media(min-width: 400px) {
    .center-link {
        margin: 0px 3px;
    }
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .center-bottom-container {
        position: fixed;
        /* Sticks to the bottom */
        bottom: 0;
        /* Align at the bottom of the page */
        left: 0;
        /* Start at the left edge */
        width: 100%;
        /* Full-width container */
        background-color: white;
        /* White background for the container */
        display: flex;
        /* Flex layout for horizontal alignment */
        justify-content: flex-start;
        /* Align items to the start of the scrollable container */
        align-items: center;
        /* Vertically center the links */
        padding: 10px 0;
        /* Padding for better spacing */
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
        /* Subtle shadow for the bar */
        z-index: 1000;
        /* Ensure it stays above other elements */
        overflow-x: auto;
        /* Allow horizontal scrolling */
        white-space: nowrap;
        /* Prevent links from wrapping to the next line */
        scrollbar-width: none;
        /* Adjust scrollbar width for modern browsers */
    }

    .center-bottom-container::-webkit-scrollbar {
        height: 6px;
        /* Adjust scrollbar height */
    }

    .center-bottom-container::-webkit-scrollbar-thumb {
        background: #ccc;
        /* Scrollbar thumb color */
        border-radius: 3px;
        display: none;
        /* Rounded edges for the scrollbar thumb */
    }

    a.center-link.colors {
        background: #003566;
        color: white;
        font-weight: bold;
    }

    .center-link {
        font-size: 15px;
        padding: 8px 16px;
        margin: 7px;
        flex-shrink: 0;
        /* Prevent links from shrinking */
        margin: 0 10px;
        /* Add spacing between links */
        text-decoration: none;
        /* Remove underline from links */
        color: #003566;
        /* Default link color */
        font-weight: bold;
    }

    button.banner_button1 {
        border-radius: 40px;
        background-color: rgb(3 51 102);
        border: none;
        color: white;
        font-size: 12px !important;
        padding: 12px 20px !important;
        margin-top: 0px !important;
    }

    button.banner_button2 {
        border-radius: 40px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 12px !important;
        padding: 8px 20px !important;
        margin-top: 0px !important;
        border: 3px solid white;
        margin-left: 5px !important;
    }

    .banner-static-s {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    /* .banner-static {
        width: 79% !important;
        background: white;
        padding: 26px;
        border-radius: 50px;
        z-index: 1;
        position: relative;
        top: 0px !important;
        left: 11% !important;
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
    }

    .banner-static.sticky {
        position: fixed;
        top: 0;
        z-index: 1000;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        width: 79% !important;
    } */

    .banner-static {
        width: 80%;
        background: white;
        padding: 26px;
        border-radius: 50px;
        z-index: 1;
        position: relative;
        top: 0px;
        /* left: 25%; */
        z-index: 1;
        margin: 0 auto;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
    }

    button.banner_button1 {
        border-radius: 40px;
        background-color: rgb(3 51 102);
        border: none;
        color: white;
        font-size: 15px !important;
        padding: 12px 25px !important;
        margin-top: 0px !important;
    }

    button.banner_button2 {
        border-radius: 40px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 15px !important;
        padding: 9px 25px !important;
        margin-top: 30px !important;
        border: 3px solid white;
        margin-left: 25px;
    }

    .banner-static-s {
        width: 92%;
        background: white;
        padding: 26px;
        border-radius: 50px;
        z-index: 1;
        position: relative;
        top: -40px;
        text-align: center;
        /* left: 25%; */
        z-index: 1;
        margin: 0 auto;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
    }
}

@media (min-width: 900px) and (max-width: 1367px) {
    .banner-static {
        width: 50%;
        background: white;
        padding: 26px;
        border-radius: 50px;
        z-index: 1;
        position: relative;
        top: -40px;
        /* left: 25%; */
        z-index: 1;
        margin: 0 auto;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
    }

    .banner-static-s {
        width: 55%;
        background: white;
        padding: 26px;
        border-radius: 50px;
        z-index: 1;
        position: relative;
        top: -40px;
        text-align: center;
        /* left: 25%; */
        z-index: 1;
        margin: 0 auto;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
    }
}

@media (min-width: 1560px) and (max-width: 1920px) {
    .banner-static {
        width: 34%;
        background: white;
        padding: 26px;
        border-radius: 50px;
        z-index: 1;
        position: relative;
        top: -40px;
        text-align: center;
        /* left: 25%; */
        z-index: 1;
        margin: 0 auto;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
    }
}