.shadow-effect {
    background: #fff;
    /* padding: 20px; */
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.10), 0 15px 12px rgba(0, 0, 0, 0.02);
}

#shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
}

.img-circle {
    border-radius: 50%;
    vertical-align: middle;
    /* max-width: 90px;
    min-height: 90px; */
    transform-style: preserve-3d;
    margin: 0 auto 17px;
}

/* #customer-testimonoals {
    margin-top: 6%;
} */

.testimonial-name {
    margin: -17px auto 0;
    display: table;
    width: auto;
    /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
    background: linear-gradient(100deg, #FF8066, #FF918D);
    /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5);
}

#customer-testimonoals .item {
    text-align: center;
    padding: 50px;
    margin-bottom: 0px;
    opacity: 0.2;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
}

#customer-testimonoals .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
}

.owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
    display: inline-block;
}

.owl-carousel .owl-dots .owl-dot span {
    /* background: #eaa400; */
    background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
}

.item img {
    height: 359px;
    width: 100% !important;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 0px;
}

button.owl-prev {
    position: absolute;
    top: 38%;
    left: -47px;
    width: 65px !important;
    background-color: #D6D6D6 !important;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 1px !important;
    font: inherit;
    border-radius: 50px;
}

button.owl-next {
    position: absolute;
    top: 38%;
    right: -47px;
    width: 65px !important;
    background-color: #D6D6D6 !important;
}

button.owl-prev span {
    font-size: 40px;
    font-weight: bold;
    color: #003566;
    top: -3px;
    position: relative;
}

button.owl-next span {
    font-size: 40px;
    font-weight: bold;
    color: #003566;
    top: -3px;
    position: relative;
}

/* #testimonial h2 {
    color: hsl(208.64deg 57.14% 30.2%);
    text-align: center;
    font-weight: bold;
    -webkit-text-decoration: underline solid hsl(208.8deg 98.04% 20%) 4px;
    text-decoration: underline solid hsl(208.8deg 98.04% 20%) 4px;
    margin-bottom: 60px;
} */