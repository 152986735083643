.paper-banner-static {
    width: 80%;
    background: white;
    padding: 26px;
    border-radius: 50px;
    z-index: 1;
    position: relative;
    top: -79px;
    left: 36%;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
}

.paper-banner-static a {
    text-decoration: none;
    padding-left: 80px;
    color: black;
    font-size: 16px;
}

/* a.paper-banner-static-color {
    background-color: rgb(0, 53, 102);
    padding: 13px 40px;
    color: white !important;
    font-weight: bold;
    border-radius: 40px;
    text-align: center;
} */

.grace-paper-banner-static {
    width: 50%;
    background: white;
    padding: 26px;
    border-radius: 20px;
    z-index: 1;
    position: relative;
    top: -40px;
    left: 23%;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 4.95px 2.6px;
}

.grace-paper-banner-static a {
    text-decoration: none;
    padding-left: 80px;
    color: black;
    font-size: 16px;
}