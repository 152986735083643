.testimonial-name {
    margin: -10px auto 0;
    display: table;
    width: 100%;
    /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
    background: white;
    /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color: black;
    box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5);
    padding-top: 20px;
    font-weight: bold;
}

.item img {
    height: 359px;
    width: 100% !important;
    object-fit: cover;
    /* border-radius: 0px; */
    margin-bottom: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.testimonial-name h5 {
    color: black;
    font-weight: bold;
}

.owl-carousel .owl-item {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.5;
    transform: scale(0.8) translateY(50px);
}

.owl-carousel .owl-item.center {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 10;
}

.owl-carousel .owl-item.left,
.owl-carousel .owl-item.right {
    opacity: 0.75;
    transform: scale(0.9) translateY(25px);
    z-index: 5;
}