.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: left !important;
    margin-bottom: 90px;
    z-index: 6666;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 9px !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #003566 !important;
    color: #003566 !important;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
    border-radius: 50% !important;
    height: 9px !important;
}

.carousel-indicators button.active {
    background-color: rgb(238 212 16) !important;
}

.carousel-indicators button {
    background-color: #003566 !important;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 80px !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: left !important;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 19% !important;
    border-radius: 77px;
    margin: 0px 47% !important;
}

button.euro_clean_banner_button {
    margin-top: 55px;
    background-color: #003566 !important;
    color: white;
    font-weight: bold;
    padding: 15px 55px;
    border-radius: 40px;
    border: none;
    text-transform: capitalize;
    --animation-depth: 0.375em;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

button.euro_clean_banner_button:hover {
    box-shadow: black 1.95px 4.95px 2.6px;
    transform: translateY(calc(-1*var(--animation-depth, .375em)));
}

.carousel-caption p {
    margin-top: 30px;
}

.carousel::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}

/* media query */

@media (max-width: 768px) {
    button.euro_clean_banner_button {
        margin-top: 0px;
        background-color: #003566 !important;
        color: white;
        font-weight: bold;
        padding: 10px 30px;
        border-radius: 40px;
        border: none;
        text-transform: capitalize;
        font-size: 12px;
    }

    .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 0px !important;
        left: 15%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: center;
        top: 15% !important;
    }

    .carousel-indicators {
        display: none !important;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        padding: 0;
        margin-right: 15%;
        margin-bottom: 1rem;
        margin-left: 15%;
        top: 88% !important;
    }

    .carousel-caption p {
        margin-top: 10px;
    }

    .carousel-item img {
        height: 450px;
        object-fit: cover;
    }

    .carousel-caption h1 {
        color: white;
        font-weight: bold;
        font-size: 20px;
    }

    .carousel-caption p {
        color: white;
        font-size: 15px;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .carousel-caption h1 {
        color: white;
        font-weight: bold;
        font-size: 20px;
    }

    .carousel-caption p {
        margin-top: 25px;
    }

    .carousel-caption p {
        margin-bottom: 0px;
    }

    .carousel-caption {
        top: 10% !important;
    }

    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 20px !important;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: left !important;
        padding: 0;
        margin-right: 15%;
        margin-bottom: 1rem;
        margin-left: 19% !important;
        border-radius: 77px;
        margin: 0px 47% !important;
    }
}