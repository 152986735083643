.me-auto.navbar-nav a {
    margin-left: 80px;
    color: black;
    --animation-depth: 0.375em;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.me-auto.navbar-nav {
    margin-left: 100px;
}

nav.navbar.navbar-expand-lg.navbar-light {
    background-color: white;
}

a.navbar-brand img {
    width: 65%;
}

button.header-contact-button {
    border-radius: 40px;
    background-color: rgb(3, 51, 102);
    border: 4px solid transparent;
    color: white;
    font-size: 18px;
    padding: 5px 35px;
    position: relative;
    margin-left: 50px;
    z-index: 1;
    box-shadow: 0 0 0 0 rgba(3, 51, 102, 0.4);
    animation: smooth-pulse 2s infinite ease-out, red-border-loading 1.5s linear infinite;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@keyframes smooth-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(3, 51, 102, 0.4);
    }

    50% {
        box-shadow: 0 0 0 15px rgba(3, 51, 102, 0.2);
    }

    100% {
        box-shadow: 0 0 0 30px rgba(3, 51, 102, 0);
    }
}

@keyframes red-border-loading {
    0% {
        border-color: #000a14 transparent transparent transparent;
    }

    25% {
        border-color: #000a14 #000a14 transparent transparent;
    }

    50% {
        border-color: transparent #000a14 #000a14 transparent;
    }

    75% {
        border-color: transparent transparent #000a14 #000a14;
    }

    100% {
        border-color: #000a14 transparent transparent transparent;
    }
}

.me-auto.navbar-nav a:hover {
    color: #003566;
    transform: translateY(calc(-1* var(--animation-depth, .375em)));
    border: none;
    border-radius: 21px;
}

button.header-contact-button:hover {
    border-radius: 40px;
    background-color: rgb(3, 51, 102);
    border: 4px solid transparent;
    color: white;
    font-size: 18px;
    padding: 5px 35px;
    position: relative;
    margin-left: 50px;
    z-index: 1;
    box-shadow: 0 0 0 0 rgba(3, 51, 102, 0.4);
    animation: none;
    box-shadow: black 1.95px 4.95px 2.6px;
    transform: translateY(calc(-1*var(--animation-depth, .375em)));
}

/* media query */

@media (max-width: 768px) {
    .me-auto.navbar-nav a {
        color: black;
        text-align: center;
        margin-left: 0px !important;
    }

    .me-auto.navbar-nav {
        margin-left: 0px !important;
    }

    button.header-contact-button {
        border-radius: 40px;
        background-color: rgb(3, 51, 102);
        border: 3px solid transparent;
        color: white;
        font-size: 18px;
        padding: 5px 35px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
        margin-bottom: 0px;
        z-index: 1;
        /* width: 50%; */
        box-shadow: 0 0 0 0 rgba(3, 51, 102, 0.4);
        animation: smooth-pulse 2s infinite ease-out, red-border-loading 1.5s linear infinite;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    button.header-contact-button {
        border-radius: 40px;
        background-color: rgb(3, 51, 102);
        border: 3px solid transparent;
        color: white;
        font-size: 18px;
        padding: 5px 35px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
        margin-bottom: 0px;
        z-index: 1;
        /* width: 50%; */
        box-shadow: 0 0 0 0 rgba(3, 51, 102, 0.4);
        animation: smooth-pulse 2s infinite ease-out, red-border-loading 1.5s linear infinite;
    }

    .me-auto.navbar-nav a {
        color: black;
        text-align: center;
        margin-left: 0px !important;
    }
}