section.grace_about_us {
    margin-top: 70px;
    display: block;
}

h2.bordered_text {
    color: #003566;
    text-align: center;
    font-weight: bold;
    position: relative;
    margin: 20px 0;
}

h2.bordered_text span {
    display: inline-block;
    background:
        linear-gradient(to right, #003566 4px, transparent 4px) 0 0,
        /* Top-left corner */
        linear-gradient(to bottom, #003566 4px, transparent 4px) 0 0,
        /* Top-left corner */
        linear-gradient(to left, #003566 4px, transparent 4px) 100% 100%,
        /* Bottom-right corner */
        linear-gradient(to top, #003566 4px, transparent 4px) 100% 100%;
    /* Bottom-right corner */

    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding: 5px 10px;
    /* Adjust padding around the text */
}



section.mobile_grace_about_us h2 {
    color: #003566;
    text-align: center;
    font-weight: bold;
    position: relative;
    margin: 20px 0;
}

/* section.mobile_grace_about_us h2::before,
section.mobile_grace_about_us h2::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: #003566;
}

section.mobile_grace_about_us h2::before {
    top: -10px;
    left: 45%;
    transform: translateX(-50%);
}

section.mobile_grace_about_us h2::after {
    bottom: -10px;
    left: 55%;
    transform: translateX(-50%);
} */


/* 
section.mobile_grace_about_us h2 {
    color: hsl(208.64deg 57.14% 30.2%);
    text-align: center;
    font-weight: bold;
    text-decoration: underline solid hsl(208.8deg 98.04% 20%) 4px;
    margin-bottom: 45px;
} */

.grace_about_us_div img {
    width: 100%;
    height: 275px;
    object-fit: cover;
    border-radius: 8px;
}

.grace_about_us_div h4 {
    background-color: hsl(208.64deg 57.14% 30.2%);
    padding-top: 35px;
    padding-bottom: 35px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 28px;
    border-radius: 8px;
}

section.mobile_grace_about_us {
    display: none;
}

/* Keyframes for bottom to top animation */
@keyframes bottomToTop {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Keyframes for top to bottom animation */
@keyframes topToBottom {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Add a class that is added when the element is in view */
.animate-bottom-top {
    animation: bottomToTop 1s ease-out forwards;
}

.animate-top-bottom {
    animation: topToBottom 1s ease-out forwards;
}

/* media query */

@media (max-width: 768px) {
    section.mobile_grace_about_us {
        display: block;
    }

    section.grace_about_us {
        display: none;
    }

    .grace_about_us_div h4 {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .grace_about_us_div img {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .grace_about_us_div {
        margin-bottom: 25px;
    }

    section.mobile_grace_about_us h2::before {
        top: -10px;
        left: 36%;
        transform: translateX(-50%);
    }

    section.mobile_grace_about_us h2::after {
        bottom: -10px;
        left: 65%;
        transform: translateX(-50%);
    }
}

@media(min-width: 768px) and (max-width: 900px) {
    section.grace_about_us h2::before {
        top: -10px;
        left: 40%;
        transform: translateX(-50%);
    }

    section.grace_about_us h2::after {
        bottom: -10px;
        left: 60%;
        transform: translateX(-50%);
    }
}