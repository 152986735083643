.image_gallery_section {
    padding: 20px 0;
    background-color: #f9f9f9;
}

.gallery-img {
    margin: 10px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    height: 120px;
    object-fit: cover;
    opacity: 0;
    transform: translateY(50px);
    /* Start position off-screen */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.gallery-img.fadeInUp {
    opacity: 1;
    transform: translateY(0);
    /* Move to normal position */
}

/* Optional: Add styles to prevent images from jumping when they load */
.gallery-img.lazyloading {
    opacity: 0.5;
    /* Lower opacity when loading */
}

.gallery-img:hover {
    transform: scale(1.1);
}

/* Different widths for images */
.small-img {
    width: 250px;
    height: 160px;
    object-fit: cover;
}

.medium-img {
    width: 301px;
    height: 160px;
    object-fit: cover;
}

.large-img {
    width: 400px;
    height: 160px;
    object-fit: cover;
}

.lg-react-element a {
    text-align: center;
}

.container .lightgallery a {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
    text-align: center;
}

section.image_gallery_section h2 {
    color: #003566;
    text-align: center;
    font-weight: bold;
    -webkit-text-decoration: underline solid hsl(208.8deg 98.04% 20%) 4px;
    text-decoration: underline solid hsl(208.8deg 98.04% 20%) 4px;
    margin-bottom: 60px;
    text-transform: capitalize;
}

section.image_gallery_section {
    margin-top: 45px;
}

.lg-react-element a {
    margin: 0px 17px;
    width: 30%;
}

.lg-react-element a img {
    width: 100%;
    height: 250px;
}

/* media query */

@media (max-width: 900px) {
    .lg-react-element a {
        display: block !important;
        width: 95% !important;
        margin: 0px !important;
    }
}

@media(min-width: 768px) {
    img.gallery-img.large-img.smalls {
        display: none;
    }
}

@media (max-width: 768px) {
    .lg-react-element {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    img.gallery-img.large-img.smalls.fadeInUp {
        width: 338px;
    }

    img.gallery-img.large-img.large {
        display: none;
    }

    .lg-react-element a {
        width: 100%;
        /* Ensure full width for grid items */
        margin: 0;
        /* Remove margins to align items properly */
    }

    .lg-react-element a img {
        width: 100%;
        height: auto;
        object-fit: contain;
        /* Maintain aspect ratio */
        border-radius: 0px;
        /* Optional: Keep consistent styles */
    }
}